<template>
  <div>
    <div class="list">
      <div class="item" :class="{active:currentMemberTypeId===item.id}" v-for="item of memberTypeList"
           :key="item.id">
        <div class="cont">
          <div class="name" style="font-size:18px">{{item.memberTypeName}}</div>
          <div class="gap-line"></div>
          <!--免费的-->
          <template v-if="item.memberType==='ordinary'">
            <div class="price-wrap">
              <div class="price">{{$t('Free')}}</div>
            </div>
            <div class="annual-fee ">
              <div class="value txt-c">
                <div class="month">{{$t('Permanent')}}</div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="price-wrap">
              <div class="price">HK${{item.baseMonthFee}}</div>
              <div class="month">/ {{$t('Monthly fee')}}</div>
            </div>
            <div class="annual-fee">
              <div class="label">{{$t('Annual fee')}}：</div>
              <div class="value">
                <el-select class="select" size="small" :placeholder="$t('PleaseChoose')" v-model="item.priceId">
                  <el-option v-for="priceItem of item.prices"
                             :key="priceItem.id"
                             :label="`${priceItem.feeStatus==='0'?`HK$${priceItem.fee}`:$t('MemberPromotionPrice', [`HK$${priceItem.promoteFee}`])}/${$t('SeveralYears', [priceItem.priceYears])}`"
                             :value="priceItem.id">
                  </el-option>
                </el-select>
              </div>
            </div>
          </template>
          <div class="introduction">
            <!--交易服務費比率-->
            <p>{{$t('ServiceFee',[parseInt(item.serviceFee*100)])}}</p>
            <!--子帳號的數目-->
            <p>{{$t('SubAccount',[item.accountNumbers])}}</p>
            <!--可提交報價數量-->
            <p>{{$t('QuotesNumber',[item.quoteNumbers+$t('Month')])}}</p>
            <!--以下是自定义的-->
            <p style="font-weight:bold" v-for="cItem of item.customizes" :key="cItem.id">{{cItem.defaultName}}</p>
          </div>
        </div>
        <template v-if="item.memberType!=='ordinary'">
          <a class="btn-submit" @click="showPaymentModal(item)">{{item.paymentTypeName}}</a>
          <div class="expired" v-if="dealTime&&currentMemberTypeId===item.id">
            {{$t('MembershipExpires',[formatDate(dealTime, 'yyyy-MM-dd')])}}
          </div>
        </template>
      </div>
    </div>
    <div class="tips">
      <span class="b">{{$t('Transaction service fee ratio')}}</span>：
      {{$t('Platform service fee as a percentage of the amount of each outbound order')}}
    </div>
    <payment-modal :current-member="currentMember" :selected="selected" @reload="getMemberTypeList" ref="paymentModal"/>
  </div>
</template>
<script>
  import PaymentModal from './PaymentModal';
  import {formatDate} from '../../../../utils';
  import {getMemberTypeList} from '../../../../api/admin-member';

  export default {
    name: 'MemberList',
    i18n: {
      messages: {
        'zh-cn': {
          'ServiceFee': '交易服务费比率：{0}％',
          'SubAccount': '子帐号的数目：{0}个',
          'QuotesNumber': '可提交报价数量：{0}',
          'Month': '个/月',
          'AnalysisReport': '旅游业数据分析报告',
          'Transaction service fee ratio': '交易服务费比率',
          'Platform service fee as a percentage of the amount of each outbound order': '平台服务费占每张出团订单金额的百分比'
        },
        'zh-hk': {
          'ServiceFee': '交易服務費比率：{0}％',
          'SubAccount': '子帳號的數目：{0}個',
          'QuotesNumber': '可提交報價數量：{0}',
          'Month': '個/月',
          'AnalysisReport': '旅遊業數據分析報告',
          'Transaction service fee ratio': '交易服務費比率',
          'Platform service fee as a percentage of the amount of each outbound order': '平台服務費佔每張出團訂單金額的百分比'
        },
        'en': {
          'ServiceFee': 'Transaction Fee Ratio：{0}％',
          'SubAccount': 'Sub Account：{0}',
          'QuotesNumber': 'Available Quotes：{0}',
          'Month': '/Month',
          'AnalysisReport': 'Tourism data analysis report',
          'Transaction service fee ratio': 'Transaction Fee Ratio',
          'Platform service fee as a percentage of the amount of each outbound order': 'Platform service fee as a percentage of the amount of each outbound order'
        }
      }
    },
    props: {
      isBuy: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        memberTypeList: [],
        currentMemberType: '',
        currentMemberTypeId: '',
        dealTime: '',
        selected: {}
      };
    },
    computed: {
      currentMember() {
        if (!this.currentMemberTypeId) {
          return {};
        }
        return this.memberTypeList.find(item => this.currentMemberTypeId === item.id) || {};
      }
    },
    components: {PaymentModal},
    created() {
      this.getMemberTypeList();
    },
    methods: {
      formatDate,
      showPaymentModal(item) {
        if (item.priceId) {
          this.selected = item;
          this.$refs.paymentModal.show();
        } else {
          this.$message.warning(this.$t('Please choose', [this.$t('Annual fee')]));
        }
      },
      // 获取会员类型列表
      getMemberTypeList() {
        getMemberTypeList().then(res => {
          let paymentType = ''; // 续费还是升级
          this.currentMemberType = res.value.currentMemberType;
          this.currentMemberTypeId = res.value.currentMemberTypeId;
          this.memberTypeList = res.value.memberTypes.map(item => {
            if (this.currentMemberTypeId === item.id) {
              paymentType = this.$t('Renewal');
              item.paymentTypeName = this.$t('Renewal');
            } else {
              if (paymentType) {
                item.paymentTypeName = this.$t('Upgrade');
              } else {
                item.paymentTypeName = this.$t('Select');
              }
            }
            return item;
          });
          this.dealTime = res.value.dealTime;
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .list{
    display:flex;align-items:stretch;justify-content:space-between;
    .item{
      position:relative;width:215px;margin-right:20px;padding-bottom:40px;text-align:center;border:1px solid #eee;
      &:nth-child(4n){margin-right:0;}
      &.active{
        border:4px solid #ff6f61;
        .btn-submit{color:#fff;border-color:#ff6f61;background-color:#ff6f61;}
      }
    }
    .name{margin-top:20px;font-size:16px;font-weight:bold;}
    .gap-line{height:1px;margin:20px 0;background-color:#eee;}
    .price-wrap{line-height:36px;}
    .price{display:inline-block;margin-right:4px;vertical-align:middle;color:#ff6f61;font-size:24px;font-weight:bold;}
    .month{display:inline-block;vertical-align:middle;font-size:14px;}
    .annual-fee{
      display:flex;flex-direction:row;align-items:center;height:50px;text-align:left;padding:0 16px;font-size:12px;
      .value{flex:1;}
      .select{width:100%;}
      /deep/ .el-input__inner{font-size:12px !important;}
    }
    .introduction{margin-top:20px;padding:0 16px;line-height:2;font-size:14px;}
    .expired{position:absolute;right:0;top:100%;left:0;margin-top:12px;color:#ff6f61;font-size:14px;font-weight:bold;}
    .btn-submit{position:absolute;right:0;bottom:0;left:0;line-height:38px;border-top:1px solid #eee;cursor:pointer;}
  }
  .tips{margin-top:50px;text-align:left;}
</style>
