<template>
  <modal width="800px" :is-show="isShow" v-if="isShow" @close-modal="hide">
    <div slot="header">
      <a class="btn-close" href="javascript:;" @click="hide">
        <img src="../../../../assets/images/icon/close.png"/>
      </a>
    </div>
    <div class="member-wrap cl">
      <div class="member-item">
        <div style="font-size: 18px" class="name">{{selected.memberTypeName}}</div>
        <div class="gap-line"></div>
        <div class="price-wrap">
          <div class="price">HK${{selected.baseMonthFee}}</div>
          <div class="month">/ {{$t('Monthly fee')}}</div>
        </div>
        <div class="introduction">
          <!--交易服務費比率-->
          <p>{{$t('ServiceFee',[parseInt(selected.serviceFee*100)])}}</p>
          <!--子帳號的數目-->
          <p>{{$t('SubAccount',[selected.accountNumbers])}}</p>
          <!--可提交報價數量-->
          <p>{{$t('QuotesNumber',[selected.quoteNumbers+$t('Month')])}}</p>
          <!--以下是自定义的-->
          <p style="font-weight: bold" v-for="cItem of selected.customizes" :key="cItem.id">{{cItem.defaultName}}</p>
        </div>
      </div>
      <div class="cont">
        <table>
          <tr>
            <!--現時等級-->
            <td class="bg b">{{$t('CurrentGrade')}}</td>
            <!--更新等級-->
            <td class="bg b">{{$t('UpdateGrade')}}</td>
            <!--年費方案-->
            <td class="bg b">{{$t('AnnualFeePlan')}}</td>
            <!--總計-->
            <td class="bg b">{{$t('total')}}</td>
          </tr>
          <tr>
            <td>{{currentMember.memberTypeName}}</td>
            <td>{{selected.memberTypeName}}</td>
            <td>HK${{totalPrice}}/{{$t('SeveralYears',[selectedYear.priceYears])}}</td>
            <td><strong>HK${{totalPrice}}</strong></td>
          </tr>
        </table>
        <div class="payment-wrap">
          <!--確定並選擇支付方式-->
          <div class="tit">{{$t('Determine and choose payment method')}}</div>
          <tabs type="card" v-model="paymentType">
            <tab-pane :label="$t('AlipayHK')" name="alipayHK" :disabled="this.selectedPaymentType != '' && this.selectedPaymentType != 'alipayHK' ">
              <el-button type="primary" :loading="alipayHKLoading" @click="createAlipayHKOrder" >{{this.alipayHKMessage}}</el-button>
            </tab-pane>
            <tab-pane :label="$t('WechatpayHK')" name="wechatpayHK" :disabled="this.selectedPaymentType != '' && this.selectedPaymentType != 'wechatpayHK' ">
              <el-button type="primary" :loading="wechatpayHKLoading" @click="createWechatpayOrder" >{{this.wechatpayHKMessage}}</el-button>
            </tab-pane>
            <tab-pane label="PayPal" name="paypal" :disabled="this.selectedPaymentType != '' && this.selectedPaymentType != 'paypal' ">
              <div class="txt-c" v-loading="payPalOrderId==='LOADING'" element-loading-background="transparent">
                <div id="paypal-button" style="display:inline-block;"
                     :style="{marginTop:payPalOrderId==='LOADING'?'80px':'20px'}">
                </div>
              </div>
            </tab-pane>
          </tabs>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
  import {Tabs, TabPane} from 'element-ui';
  import Modal from '../../../../components/Modal';
  import {initPaypal} from '../../../../utils/thirdParty';
  import {memberBuy, getMemberPayStatus, verifyPaypal} from '../../../../api/admin-member';

  export default {
    name: 'PaymentModal',
    i18n: {
      messages: {
        'zh-cn': {
          'ServiceFee': '交易服务费比率：{0}％',
          'SubAccount': '子帐号的数目：{0}个',
          'QuotesNumber': '可提交报价数量：{0}',
          'Month': '个/月',
          'AnalysisReport': '旅游业数据分析报告',
          'Transaction service fee ratio': '交易服务费比率',
          'Platform service fee as a percentage of the amount of each outbound order': '平台服务费占每张出团订单金额的百分比'
        },
        'zh-hk': {
          'ServiceFee': '交易服務費比率：{0}％',
          'SubAccount': '子帳號的數目：{0}個',
          'QuotesNumber': '可提交報價數量：{0}',
          'Month': '個/月',
          'AnalysisReport': '旅遊業數據分析報告',
          'Transaction service fee ratio': '交易服務費比率',
          'Platform service fee as a percentage of the amount of each outbound order': '平台服務費佔每張出團訂單金額的百分比'
        },
        'en': {
          'ServiceFee': '交易服務費比率：{0}％',
          'SubAccount': '子帳號的數目：{0}個',
          'QuotesNumber': '可提交報價數量：{0}',
          'Month': '個/月',
          'AnalysisReport': '旅遊業數據分析報告',
          'Transaction service fee ratio': '交易服務費比率',
          'Platform service fee as a percentage of the amount of each outbound order': '平台服務費佔每張出團訂單金額的百分比'
        }
      }
    },
    components: {Modal, Tabs, TabPane},
    props: {
      i18n: Object,
      currentMember: Object,
      selected: Object
    },
    data() {
      return {
        isShow: false,
        timer: null,
        selectedPaymentType: '', // 已選擇的付款方式
        paymentType: 'alipayHK', // 選中的付款tab
        alipayHKLoading: false,
        alipayHKMessage: '支付寶（香港）',
        wechatpayHKLoading: false,
        wechatpayHKMessage: '微信支付（香港）',
        validityPeriod: '',
        aliPC2Mobile: '',
        payPalOrderId: '',
        orderId: ''
      };
    },
    computed: {
      selectedYear() {
        const priceId = this.selected.priceId;
        if (this.selected.priceId) {
          return this.selected.prices.find(item => item.id === priceId) || this.selected.prices[0];
        } else {
          return this.selected.prices[0];
        }
      },
      totalPrice() {
        if (this.selectedYear.id) {
          return this.selectedYear.feeStatus === '0' ? this.selectedYear.fee : this.selectedYear.promoteFee;
        } else {
          return '--';
        }
      }
    },
    destroyed() {
      this.clearTimer();
    },
    watch: {
      paymentType: function (value) {
        this.clearTimer();
        switch (value) {
          case 'aliPC2Mobile':
            // Do nothing
            // if (!this.aliPC2Mobile) {
            //   this.createOrder('aliPC2Mobile', (data) => {
            //     this.aliPC2Mobile = data;
            //   });
            // }
            break;
          case 'paypal':
            this.onPaypal();
            break;
        }
      }
    },
    methods: {
      createAlipayHKOrder() {
        this.$msgbox({
          title: '確認支付方式',
          message: '确定使用支付寶（香港）支付這張訂單嗎',
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = '創建訂單...';
              this.selectedPaymentType = 'alipayHK';
              this.alipayHKLoading = true;
              this.alipayHKMessage = '正在創建支付寶香港訂單...';
              this.createOrder('alipayHK').then(response => {
                this.alipayHKMessage = '訂單創建成功，請在跳轉付款窗口掃QRCode付款';
                instance.confirmButtonLoading = false;
                window.open(response.pay_apptrade);
                done();
              }).catch(error => {
                this.alipayHKMessage = error;
              });
            } else {
              done();
            }
          }
        });
      },
      createWechatpayOrder() {
        this.$msgbox({
          title: '確認支付方式',
          message: '确定使用微信支付（香港）支付這張訂單嗎',
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = '創建訂單...';
              this.selectedPaymentType = 'wechatpayHK';
              this.wechatpayHKLoading = true;
              this.wechatpayHKMessage = '正在創建微信香港訂單...';
              this.createOrder('wechatpayHK').then(response => {
                this.wechatpayHKMessage = '訂單創建成功，請在跳轉付款窗口掃QRCode付款';
                instance.confirmButtonLoading = false;
                window.open(response.pay_apptrade);
                done();
              }).catch(error => {
                this.wechatpayHKMessage = error;
              });
            } else {
              done();
            }
          }
        });
      },
      show() {
        Object.assign(this.$data, this.$options.data());
        this.isShow = true;
      },
      hide() {
        this.clearTimer();
        this.isShow = false;
      },
      // 清除查询订单定时器
      clearTimer() {
        if (this.timer) {
          clearInterval(this.timer);
          this.timer = null;
        }
      },
      // 下单获取支付参数
      createOrder(payPlatform) {
        const data = {
          memberTypeId: this.selectedYear.memberTypeId,
          memberType: this.selected.memberType,
          payPlatform,
          priceYears: this.selectedYear.priceYears
        };
        return new Promise((resolve, reject) => {
          memberBuy(data).then(res => {
            this.orderId = res.value.orderId;
            resolve(res.value.param);
            // 開始每秒詢問一次付款是否成功
            if (!this.timer) {
              this.timer = setInterval(this.getOrderPayStatus, 900);
            }
          }).catch(e => {
            reject(e);
          });
        });
      },
      // paypal支付
      onPaypal() {
        if (this.payPalOrderId) {
          return false;
        }
        this.payPalOrderId = 'LOADING';
        this.createOrder('paypal').then(data => {
          this.payPalOrderId = data.payPalOrderId;
          initPaypal().then(() => {
            paypal.Button.render({
              env: 'production',
              locale: 'zh_HK', // zh_CN、zh_HK、en_US
              style: {
                size: 'large'
              },
              payment: (data, actions) => {
                return this.payPalOrderId;
              },
              onAuthorize: (data, actions) => {
                return verifyPaypal(this.payPalOrderId).then(res => {
                  this.getOrderPayStatus();
                });
              }
            }, '#paypal-button');
          });
        }).catch(e => {
          this.payPalOrderId = '';
        });
      },
      // 查询订单支付状态
      getOrderPayStatus() {
        getMemberPayStatus(this.orderId).then(res => {
          if (res.value === 'success') {
            this.clearTimer();
            this.paymentSuccessful();
          }
        });
      },
      // 支付成功
      paymentSuccessful() {
        this.$alert(this.$t('Payment successful'), this.$t('Prompt'), {
          customClass: 'successful',
          type: 'success',
          showClose: false,
          callback: action => {
            this.$emit('reload');
            this.hide();
          }
        });
      }
    }
  };
</script>
<style scoped lang="less">
  .member-wrap{
    padding:40px 30px;
    .cont{margin-left:235px;}
    table{
      width:100%;border:1px solid #f4f4f4;
      td{padding:9px 8px;}
      .bg{background-color:#f4f4f4;}
    }
  }
  .member-item{
    float:left;width:215px;min-height:330px;padding:3px;text-align:center;border:1px solid #eee;background-color:#f4f4f4;
    .name{margin-top:20px;font-size:16px;font-weight:bold;}
    .gap-line{height:1px;margin:24px 0;background-color:#eee;}
    .price-wrap{line-height:36px;}
    .price{display:inline-block;vertical-align:middle;color:#ff6f61;font-size:24px;font-weight:bold;}
    .month{display:inline-block;margin-left:4px;vertical-align:middle;font-size:12px;}
    .introduction{margin-top:20px;padding:0 16px;text-align:left;line-height:2;font-size:12px;}
  }
  .payment-wrap{
    margin-top:30px;text-align:left;
    .tit{margin-bottom:8px;font-size:16px;}
  }
  .btn-close{position:absolute;top:8px;right:8px;margin-top:0 !important;}
  .qrcode{position:relative;width:200px;height:210px;margin:0 auto;}
</style>
